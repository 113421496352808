<template>
  <div>
    <!-- <div class="ml-4"> -->
    <div v-if="multiplayer" class="ml-4">
      <a @mousedown="show" class="relative block cursor-pointer">
        <svg width="28" height="24" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg"><g fill-rule="nonzero" fill="none"><path d="M5.361 19c.338 0 .574-.169.971-.56l2.457-2.404c-.473-.4-.861-1.03-.861-2.476V7.676c0-2.724 1.57-4.34 4.162-4.34H18L17.992 3c-.127-1.82-1.267-3-3.2-3H3.208C1.275 0 0 1.216 0 3.328v8.715c0 2.112 1.275 3.47 3.208 3.47H4.61v2.582c0 .55.278.905.751.905zm13.71 1c.489 0 .763-.371.763-.924v-2.644h.909c1.963 0 3.257-1.385 3.257-3.531V7.396C24 5.23 22.706 4 20.743 4h-8.494C10.2 4 9 5.231 9 7.396V12.9c0 2.146 1.2 3.531 3.249 3.531h2.742l3.095 2.988c.403.399.643.58.985.58z" fill="#0554A1" style="mix-blend-mode:multiply"/></g></svg>

        <transition name="fade">
          <div v-if="counter" class="absolute top-0 right-0 -mt-1 -mr-1 text-3xs lg:hidden">
            <span class="bg-red-600 rounded-chat inline-block text-white px-1">{{ counter.toLocaleString() }}</span>
          </div>
        </transition>
      </a>
    </div>
    <transition name="fade">
      <div v-show="showing" v-away="awayThing" class="fixed chat-position z-20 bg-blur">
        <div @click.capture="focusInput" class="flex flex-col justify-end overflow-hidden h-full">
          <div ref="feed" class="max-h-full overflow-auto">
            <ul v-if="chat.length" class="my-2">
              <li class="p-2 text-center text-2xs text-gray-600">No earlier msgs</li>
              <li v-for="(m, i) in chat" :key="m.when" class="mx-2 my-1">
                <chat-msg :msg="m" :prev="chat[i - 1]"/>
              </li>
            </ul>
            <div v-else class="p-3 text-center text-2xs text-gray-600">No msgs yet</div>
          </div>
          <div @click.stop class="bg-chat-box pr-3 flex items-end">
            <div ref="msg" @input="msgInput" @keydown="maySend" @keyup.stop :class="{ 'empty': noMsg }" v-focus @paste="pasteEvent" contenteditable class="flex-auto focus:outline-none text-base lg:text-xs break-words py-3 pl-3"></div>
            <div class="py-2 lg:py-3">
              <a @click="sendMsg">
                <svg width="26" height="26" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M11.88 22.201c5.994 0 10.957-4.963 10.957-10.957 0-5.983-4.974-10.957-10.968-10.957C5.886.287.923 5.261.923 11.244c0 5.994 4.973 10.957 10.957 10.957zm0-4.92c-.516 0-.881-.354-.881-.87v-6.09l.097-2.471-1.182 1.407-1.45 1.46a.855.855 0 01-.623.259c-.484 0-.838-.376-.838-.86 0-.247.075-.451.226-.601l3.963-3.964c.237-.237.44-.333.688-.333.258 0 .473.107.698.333l3.953 3.964a.88.88 0 01.247.601c0 .484-.365.86-.86.86a.868.868 0 01-.622-.258l-1.44-1.461-1.181-1.418.086 2.481v6.091c0 .516-.365.87-.881.87z" fill="#1B85CB" fill-rule="nonzero"/></g></svg>
              </a>
            </div>
          </div>
        </div>
        <div @click.stop="hide" class="absolute top-0 right-0 text-sm text-gray-800 m-2 py-1 px-2 border border-gray-800 rounded-full bg-blur lg:hidden cursor-pointer shadow">&times; close</div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import ChatMsg from '@/components/ChatMsg'

  export default {
    name: 'Chat',
    data () {
      return {
        counter: 0,
        showing: false,
        form: {
          msg: null
        },
        chat: []
      }
    },
    computed: {
      noMsg () {
        return !this.form.msg || !this.form.msg.length
      },

      ...mapState(['game', 'io', 'innerWidth']),
      ...mapGetters(['multiplayer'])
    },
    methods: {
      events () {
        if (!this.io) return

        this.io.on('chat', (payload) => {
          this.chat.push(payload)

          if (this.showing) return
          this.counter++
        })
      },
      msgInput ({ target }) {
        this.form.msg = target.innerText
      },
      maySend (e) {
        const { code, shiftKey } = e
        if (code === 'Enter' && !shiftKey) {
          e.preventDefault()
          this.sendMsg()
        } else if (code === 'Escape') {
          this.hide()
        }
      },
      sendMsg () {
        if (this.noMsg) return

        this.io.emit('chat', {
          msg: this.form.msg
        })

        this.form.msg = null
        this.$refs.msg.innerText = ''
      },
      show () {
        this.showing = true
        this.counter = 0

        this.$nextTick(() => {
          if (!this.$refs.feed) return
          this.$refs.feed.scrollTop = this.$refs.feed.scrollHeight
        })
      },
      hide () {
        this.form.msg = null

        if (this.$refs.msg) {
          this.$refs.msg.blur()
          this.$refs.msg.innerHTML = ''
        }

        if (this.lgScreen()) return

        this.showing = false
      },
      awayThing (e) {
        if (this.$el.contains(e.target)) return
        this.innerWidth < 768 && this.hide()
      },
      pasteEvent (e) {
        setTimeout(() => {
          const txtOnly = this.$refs.msg.innerHTML.replace(/(<([^>]+)>)/ig, '')
          const editable = this.$refs.msg
          editable.innerHTML = txtOnly
          const range = document.createRange()
          const sel = window.getSelection()
          range.setStart(editable.childNodes[0], editable.innerText.length)
          range.collapse(true)
          sel.removeAllRanges()
          sel.addRange(range)
        }, 0)
      },
      focusInput () {
        this.$nextTick(() => {
          this.$refs.msg && this.$refs.msg.focus()
        })
      }
    },
    async mounted () {
      this.events()
      // get recent chat
      const c = await this.$store.dispatch('getChat', this.game._id)
      while (c.chat.length) {
        this.chat.push(c.chat.pop())
      }
      this.showing = this.lgScreen() && this.multiplayer
    },
    watch: {
      io (v) {
        v && this.events()
      },
      game () {
        if (this.lgScreen()) {
          this.showing = this.multiplayer
        }
      },
      innerWidth () {
        if (this.lgScreen() && this.multiplayer) {
          this.show()
        }
      }
    },
    directives: {
      away: {
        inserted (_, binding) {
          document.addEventListener('click', binding.value)
        }
      }
    },
    components: { ChatMsg }
  }
</script>
