import { mapState, mapGetters } from 'vuex'
import Dice from '@/components/Dice'
import Loader from '@/components/Loader'

export default {
  data () {
    return {
      copied: false
    }
  },
  computed: {
    ...mapState(['innerWidth']),
    ...mapGetters(['me', 'loggedIn'])
  },
  methods: {
    playerName (p, txt = 'me', full = true) {
      if (!p) return
      const id = typeof p === 'object' ? p.id : p
      const name = p.temp || p.name || id
      const myself = id === this.me._id
      if (!full && myself) return txt
      return name + (id === this.me._id ? ` (${txt})` : '')
    },
    playerScore (p) {
      if (!p || !p.score) return 0
      return p.score.toLocaleString()
    },

    diceScore (dice, ignoreUnscored = false) {
      const o = dice.slice(0).sort()
      let [sum, count] = [0, 1]

      if (o.join('') === '123456') return 1000

      let i = 0
      while (i <= o.length) {
        const prev = o[i - 1]
        const v = o[i]

        if (v !== prev) {
          const mod = prev === 1 ? 1000 : 100
          if (count === 6) {
            sum += prev * (mod * 4)
          } else if (count === 5) {
            sum += prev * (mod * 3)
          } else if (count === 4) {
            sum += prev * (mod * 2)
          } else if (count === 3) {
            sum += prev * mod
          } else {
            sum += (prev === 1 ? 100 : prev === 5 ? 50 : 0) * count
          }
          count = 1
        } else {
          count++
        }
        i++
      }

      if (ignoreUnscored) return sum

      // check for dodgy dice
      for (const u of [2, 3, 4, 6]) {
        const amnt = o.filter(i => i === u).length
        if (amnt > 0 && amnt < 3) {
          sum = 0
        }
      }
      return sum
    },

    copyGameUrl () {
      const url = window.location.href
      if (typeof window.navigator.share === 'function') {
        window.navigator.share({ url }).catch(() => {})
      } else {
        copyToClipboard(url)
        this.copied = true
      }
    },
    mdScreen () {
      return this.innerWidth >= 768
    },
    lgScreen () {
      return this.innerWidth >= 1024
    }
  },
  watch: {
    copied (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.copied = false
        }, 2000)
      }
    }
  },
  directives: {
    focus: {
      inserted (el) {
        el.focus()
      }
    }
  },
  components: { Dice, Loader }
}

function copyToClipboard (str) {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  if (selected) {
    document.getSelection().removeAllRanges()
    document.getSelection().addRange(selected)
  }
}
