<template>
  <img :src="src" :alt="alt" :class="dropClass" class="max-w-none w-full"/>
</template>

<script>
  export default {
    name: 'Dice',
    data () {
      return {
        dice: {
          0: require('@/assets/d-0.svg'),
          1: require('@/assets/d-1.svg'),
          2: require('@/assets/d-2.svg'),
          3: require('@/assets/d-3.svg'),
          4: require('@/assets/d-4.svg'),
          5: require('@/assets/d-5.svg'),
          6: require('@/assets/d-6.svg'),
          n: require('@/assets/d-7.svg')
        }
      }
    },
    props: {
      val: [Number, Boolean],
      drop: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      alt () {
        return `Number ${this.val} die`
      },
      src () {
        if (this.val === undefined) return this.dice.n

        return this.dice[this.val]
      },
      dropClass () {
        return this.drop && 'dice-drop'
      }
    }
  }
</script>

<style>
  .dice-drop {
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, .2));
  }
</style>
