import axios from 'axios'

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL || '',
  withCredentials: true
})

export default {
  user ({ state, commit }) {
    return api.get('/auth/check').then(({ data }) => {
      // console.log('logged in as', data.username)
      commit('PROFILE', data)
    }).catch(handle)
  },

  guest ({ commit }) {
    return api.post('/auth/guest').then(({ data }) => {
      commit('PROFILE', data)
    }).catch(handle)
  },

  checkUsername (_, val) {
    return api.get('/usernames', { params: { s: val } }).then(({ data }) => data)
  },
  // register (_, form) {
  //   return api.post('/register', form).then(({ data }) => data)
  // },

  addEmail ({ commit }, form) {
    return api.put('/auth/add-email', form).then(({ data }) => {
      commit('PROFILE', data)
    })
  },
  confirmEmail ({ commit }, form) {
    return api.put('/auth/confirm-email', form).then(({ data }) => {
      commit('PROFILE', data)
    })
  },

  changeUsername ({ commit }, form) {
    return api.put('/your/name', form).then(({ data }) => {
      commit('PROFILE', data)
    })
  },
  setTempName ({ commit }, form) {
    return api.put('/guest/name', form).then(({ data }) => {
      commit('PROFILE', data)
    })
  },

  login ({ state, commit }, form) {
    if (state.acc?._id) return
    return api.post('/auth/login', form)
  },
  loginConfirm ({ state, commit }, form) {
    if (state.acc?._id) return
    return api.post('/auth/confirm', form).then(({ data }) => {
      commit('PROFILE', data)
    })
  },

  logout ({ state, commit }) {
    if (!state.acc) return
    return api.get('/auth/logout').then(() => {
      commit('SIGN_OUT')
    }).catch(handle)
  },

  newGame ({ state }) {
    return api.post('/new').then(({ data }) => data).catch(handle)
  },

  getGame ({ commit }, id) {
    return api.get(`/game/${id}`).then(({ data }) => {
      commit('SET_GAME', data)
    })
  },

  getChat (_, id) {
    return api.get(`/chat/${id}`).then(({ data }) => data)
  },

  checkName ({ state, commit }) {
    if (state.acc) return

    commit('CHECK_NAME', true)
    return new Promise((resolve) => {
      document.getElementById('next-route').addEventListener('click', (e) => {
        commit('CHECK_NAME', false)
        resolve(e)
      }, { once: true })
    })
  },
  leaderboard ({ commit }) {
    api.get('/hands').then(({ data }) => {
      commit('BEST_HANDS', data)
    })
  },

  getStats (_, path) {
    return api.get(path).then(({ data }) => data)
  },

  pixel (_, p = {}) {
    const { href } = window.location
    return api.get('/pixel', {
      params: { u: encodeURIComponent(href), ...p }
    }).catch(handle)
  }
}

function handle () {}

// function handle (err) {
//   console.log(err)
// }
