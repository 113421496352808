export default {
  loggedIn ({ acc }) {
    if (!acc) return false
    if (acc.cc) return false
    return !!acc
  },
  me ({ acc }) {
    return acc || {}
  },
  loggingIn ({ acc }) {
    if (!acc) return false
    return !!acc.ce
  },

  players ({ game }) {
    return game.players || []
  },
  turn ({ game }) {
    return game.turn
  },
  turnScore ({ game }) {
    return game.turnPoints
  },
  multiplayer ({ game }, { players }) {
    if (game.nadabot) return false
    return players.length > 1
  },
  rankings (_, { players }) {
    if (!players || !players.length) return players
    return [...players].sort((a, b) => {
      return b.score - a.score
    })
  },
  displayName ({ acc }) {
    return acc ? (acc.tempName || acc.username) : null
  },
  myGame ({ acc, game }) {
    return game.owner === acc._id
  },
  myGo ({ acc, game }, { turn }) {
    if (!game.started) return false
    return turn && turn.id === acc._id
  },
  myPoints ({ acc }, { players }) {
    const p = players.find(p => p.id === acc._id)
    if (!p) return 0
    return p.score || 0
  },
  inGame ({ acc }, { players }) {
    const p = players.findIndex(p => p.id === acc._id)
    return p >= 0
  }
}
