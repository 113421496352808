<template>
  <div :class="headerBg" class="min-h-ios flex flex-col">
    <header>
      <router-link to="/" class="flex-auto" name="NADA">
        <h1 class="text-white">
          <img src="@/assets/nada.svg" class="logo" alt="NADA, a dice game">
        </h1>
      </router-link>
      <transition name="fade">
        <div v-if="!isHome" class="absolute left-0 top-0 py-2 pl-2">
          <router-link to="/" class="lg:text-lg text-blue-800 font-medium">&larr;</router-link>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="gameName" :class="{ 'lg:bg-gray-300': chatwidth }" class="flex items-center absolute right-0 top-0 py-2 md:py-3 lg:py-2 pr-3 pl-6 justify-end chatwidth">
          <div @click="copyGameUrl" class="font-mono whitespace-nowrap cursor-pointer blend-multi" style="color:#0554A1">
            {{ gameName }}
          </div>
          <chat />
        </div>
      </transition>
      <transition name="fade">
        <div v-if="!gameName && loggedIn" class="absolute right-0 top-0 py-2 md:py-3 lg:py-2 pr-3">
          <router-link to="/you" class="p-1 block text-blue-800">
            <svg width="21px" height="22px" viewBox="0 0 21 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="one-copy-11" transform="translate(-6.000000, -5.000000)" fill="currentColor" fill-rule="nonzero">
                  <path d="M16.2421875,15.8632812 C19.03125,15.8632812 21.3046875,13.3789062 21.3046875,10.3554688 C21.3046875,7.35546875 19.0429688,5 16.2421875,5 C13.4648438,5 11.1796875,7.40234375 11.1796875,10.3789062 C11.1914062,13.390625 13.453125,15.8632812 16.2421875,15.8632812 Z M16.2421875,14.09375 C14.53125,14.09375 13.0664062,12.453125 13.0664062,10.3789062 C13.0664062,8.33984375 14.5078125,6.76953125 16.2421875,6.76953125 C17.9882812,6.76953125 19.4179688,8.31640625 19.4179688,10.3554688 C19.4179688,12.4296875 17.9648438,14.09375 16.2421875,14.09375 Z M23.6015625,26.703125 C25.546875,26.703125 26.4726562,26.1171875 26.4726562,24.828125 C26.4726562,21.7578125 22.59375,17.3164062 16.2421875,17.3164062 C9.87890625,17.3164062 6,21.7578125 6,24.828125 C6,26.1171875 6.92578125,26.703125 8.87109375,26.703125 L23.6015625,26.703125 Z M24.1640625,24.9335938 L8.3203125,24.9335938 C8.015625,24.9335938 7.88671875,24.8515625 7.88671875,24.6054688 C7.88671875,22.6835938 10.8632812,19.0859375 16.2421875,19.0859375 C21.609375,19.0859375 24.5859375,22.6835938 24.5859375,24.6054688 C24.5859375,24.8515625 24.46875,24.9335938 24.1640625,24.9335938 Z" id="person"></path>
                </g>
              </g>
            </svg>
          </router-link>
        </div>
      </transition>
    </header>
    <router-view/>
    <div v-if="isHome" class="bg-orange-300 p-4 text-blue-800">
      <router-link to="/how" class="md:flex max-w-xl mx-auto">
        <div class="flex">
          <div v-for="n of 6" :key="n" class="w-6 mr-3">
            <dice :val="randomDie()"/>
          </div>
        </div>
        <p class="font-medium mt-3 md:mt-0">How do you play NADA?</p>
      </router-link>
    </div>
    <div v-if="isHome" class="min-h-home bg-teal-400 p-4 text-white">
      <div class="max-w-xl mx-auto">
        <div class="mb-4 flex items-end justify-between">
          <div>
            <p class="font-medium text-xl">Leaderboard</p>
            <p class="text-2xs">Most points banked in a turn ⤵</p>
          </div>
          <div class="flex text-2xs">
            <label class="ml-2 px-2 py-1 border border-white rounded-full">
              Recent
              <input v-model="board" type="radio" value="recent" name="leaderboard">
            </label>
            <label class="ml-2 px-2 py-1 border border-white rounded-full">
              All time
              <input v-model="board" type="radio" value="all" name="leaderboard">
            </label>
          </div>
        </div>
        <div v-if="!leaderboard" class="text-xs">Loading...</div>
        <div v-else>
          <div v-for="hand in hands" :key="hand._id" class="mb-4">
            <p class="font-mono text-3xs">{{ handDate(hand.when) }}</p>
            <div class="flex items-end">
              <div class="truncate">
                <p class="font-medium leading-tight">{{ hand.playerName || hand.playerId }}</p>
              </div>
              <div class="border-b border-dashed border-white flex-auto mb-1 mx-2"></div>
              <div class="font-bold font-mono leading-tight">{{ hand.score.toLocaleString() }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showFooter" class="bg-orange-300 p-4 sm:text-xs">
      <!-- <div class="w-full"><loader/></div> -->
      <div class="max-w-xl m-auto">
        <ul class="flex flex-wrap mb-2">
          <li class="mr-3">
            <router-link to="/" class="font-bold text-teal-600">NADA</router-link>
          </li>
          <li class="mr-3">
            <router-link to="/how" class="font-medium">✴️ How to play</router-link>
          </li>
        </ul>
        <ul class="flex flex-wrap mb-1">
          <!-- <li class="mr-3">
            <router-link to="/privacy" class="text-gray-800">Privacy</router-link>
          </li>
          <li class="mr-3">
            <router-link to="/terms" class="text-gray-800">Terms of Service</router-link>
          </li> -->
          <li class="mr-3 text-xs">
            <a href="mailto:mail@nada.games" class="text-gray-800">💌 mail@nada.games</a>
          </li>
        </ul>
        <p class="text-2xs lg:text-3xs text-gray-700">&copy; 2020 NADA</p>
      </div>
      <div class="hidden">
        <p>A thing by <a href="https://n-kort.net" target="_blank" rel="noopener" class="underline">N Kort</a></p>
      </div>
    </div>

    <transition name="fade">
      <name-check v-if="checkName"/>
    </transition>
    <!-- for clicks.. -->
    <a id="next-route"></a>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import NameCheck from '@/components/NameCheck'
  import Chat from '@/components/Chat'
  import { format } from 'date-fns'

  export default {
    name: 'App',
    data () {
      return {
        board: 'recent'
      }
    },
    computed: {
      isHome () {
        return this.$route.name === 'home'
      },
      gameName () {
        if (this.$route.name !== 'game') return
        return this.copied ? 'copied!' : this.$route.params.id
      },
      headerBg () {
        if (this.$route.name === 'how') return 'bg-green-400'
        if (!this.gameName) return 'bg-blue-400'
        return this.game.started ? 'bg-blue-400' : 'bg-gray-300'
      },
      chatwidth () {
        return this.lgScreen() && this.multiplayer
      },
      showFooter () {
        const nope = ['game', 'how']
        return !nope.includes(this.$route.name)
      },
      hands () {
        if (!this.leaderboard) return []
        return this.leaderboard[this.board]
      },

      ...mapState(['game', 'checkName', 'leaderboard']),
      ...mapGetters(['multiplayer'])
    },
    methods: {
      handDate (d) {
        return format(new Date(d), 'MMM d, yyyy')
      },
      randomDie () {
        return Math.floor(Math.random() * 6) + 1
      },

      screenSizing () {
        const vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
        this.$store.commit('CHANGE_WIDTH')
      }
    },
    mounted () {
      this.$store.dispatch('user')

      window.addEventListener('resize', this.screenSizing)
      this.screenSizing()

      if (!('Notification' in window)) return
      Notification.requestPermission()
    },
    components: { NameCheck, Chat }
  }
</script>

<style>
  @import './style/global.css';
</style>
