import Vue from 'vue'
import Vuex from 'vuex'
import getters from '@/store/getters'
import actions from '@/store/actions'
import mutations from '@/store/mutations'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    checkName: false,

    game: {},

    leaderboard: null,

    msgs: [],
    sounds: getLocalObj('nada-sounds', '{ "mute": false }'),

    acc: null, // { user }

    io: null,

    innerWidth: window.innerWidth
  },
  getters,
  actions,
  mutations
})

// function getLocal (key, def = false) {
//   if (!window.localStorage) return false
//   return window.localStorage.getItem(key) || def
// }

function getLocalObj (key, fallback = false) {
  if (!window.localStorage) return false
  return JSON.parse(window.localStorage.getItem(key) || fallback)
}
