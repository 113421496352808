<template>
  <div class="flex">
    <div v-for="(n, i) of dice" :key="i" class="w-6 mr-3">
      <dice :val="n" :drop="false" class="mr-3"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Loader',
    data () {
      return { dice: [], int: null }
    },
    mounted () {
      this.dice = roll()
      let i = 0
      this.int = setInterval(() => {
        const die = roll(1)[0]
        this.$set(this.dice, i, die)
        i = i < 5 ? i + 1 : 0
      }, 66)
    },
    destroyed () {
      window.clearInterval(this.int)
    }
  }

  function roll (n = 6) {
    const res = Array(n).fill(0).map(() => {
      return Math.floor(Math.random() * 6) + 1
    })
    return res
  }
</script>
