<template>
  <div class="fixed inset-0 flex p-3">
    <div class="rounded-lg bg-green-400 text-white max-w-sm shadow-lg m-auto px-6 py-5 w-full">
      <p class="text-lg mb-3">Hello! {{ aGuest ? 'Pick a name' : 'Log in to your account' }}</p>
      <div v-if="!aGuest">
        <p class="text-2xs mb-2">{{ msg }}</p>
        <loader v-if="busy" class="px-1 py-2"/>
        <template v-else-if="loggingIn">
          <form @submit.prevent="sendCode" class="mb-1 flex">
            <input v-model="confirm.code" v-focus placeholder="Confirmation code" type="text" class="w-full rounded py-1 px-2 text-black" required>
            <button class="rounded bg-orange-400 text-white shadow py-1 px-3 hover:bg-yellow-500 ml-3">Confirm</button>
          </form>
          <p class="text-2xs">Didn't get one? <a @click="loggingIn = false" class="underline cursor-pointer">Try again</a></p>
        </template>
        <form v-else @submit.prevent="sendEmail" class="mb-3 flex">
          <input v-model="form.email" placeholder="Email address*" type="email" class="w-full rounded py-1 px-2 text-black" required>
          <button class="rounded bg-orange-400 text-white shadow py-1 px-3 hover:bg-yellow-500 ml-3 whitespace-nowrap">Sign in</button>
        </form>

        <div v-if="formError">
          <p class="text-2xs text-red-600 max-w-sm">{{ formError }}</p>
        </div>
      </div>

      <template v-if="!loggingIn && !busy && !aGuest">
        <p class="my-4 text-xs">or &searr;</p>
        <div>
          <p class="text-sm">
            <button @click="createGuest" class="bg-blue-500 py-2 px-3 rounded-md cursor-pointer inline-block hover:bg-teal-600">Play as a guest</button>
          </p>
        </div>
      </template>
      <div v-if="aGuest">
        <username-form @next="nextRoute"/>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import UsernameForm from '@/components/UsernameForm'

  export default {
    name: 'NameCheck',
    data () {
      return {
        busy: false,
        loggingIn: false,
        form: { email: null, returnTo: window.location.pathname },
        confirm: { code: null },
        formError: null,

        aGuest: false,

        newName: null
      }
    },
    computed: {
      msg () {
        if (this.loggingIn) {
          return '✓ We sent you a verification code. Check your email and paste it in here.'
        } else {
          return '→ Use the email you signed up with, you\'ll get a verification code.'
        }
      },

      ...mapGetters(['me', 'loggedIn', 'displayName'])
    },
    methods: {
      sendEmail () {
        if (!this.form.email?.length) return

        this.busy = true
        this.formError = null
        this.login(this.form).then(() => {
          this.loggingIn = true
          this.busy = false
        }).catch(() => {
          this.formError = 'That did not work'
          this.busy = false
        })
      },
      sendCode () {
        if (!this.confirm.code?.length) return

        this.busy = true
        this.formError = null
        this.loginConfirm({
          email: this.form.email,
          password: this.confirm.code
        }).then(() => {
          this.busy = false
          this.nextRoute()
        }).catch(() => {
          this.formError = 'That did not work'
          this.busy = false
        })
      },
      createGuest () {
        this.guest().then(() => {
          this.aGuest = true
        })
      },
      nextRoute () {
        document.getElementById('next-route').click()
      },

      ...mapActions(['guest', 'login', 'loginConfirm'])
    },
    watch: {
      loggedIn (newVal) {
        if (newVal) {
          this.nextRoute()
        }
      }
    },
    mounted () {
      if (this.loggedIn) {
        this.nextRoute()
      }
    },
    components: { UsernameForm }
  }
</script>
