export default {
  SIGN_OUT (state) {
    state.acc = null
  },
  CHECK_NAME (state, bool) {
    state.checkName = bool
  },

  PROFILE (state, p) {
    state.acc = p
  },

  IO (state, io) {
    state.io = io
  },

  SET_GAME (state, game) {
    state.game = game
  },

  IM_BACK (state) {
    const { _id } = state.acc
    if (!_id) return

    state.game.players.forEach((p) => {
      if (p.id === _id) {
        delete p.away
      }
    })
    const pid = state.game.skippedPlayers.indexOf(_id)
    state.game.skippedPlayers.splice(pid, 1)
  },

  BEST_HANDS (state, data) {
    state.leaderboard = data
  },

  SOUNDS (state, settings) {
    state.sounds = settings
    storeObj('nada-sounds', settings)
  },

  CHANGE_WIDTH (state) {
    state.innerWidth = window.innerWidth
  }
}

function storeObj (key, obj = false) {
  if (!window.localStorage) return
  window.localStorage.setItem(key, JSON.stringify(obj))
}
