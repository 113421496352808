import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/join',
      name: 'join',
      component: () => import(/* webpackChunkName: "join" */ '@/views/Join')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/Login')
    },
    // {
    //   path: '/register',
    //   name: 'register',
    //   component: () => import(/* webpackChunkName: "register" */ '@/views/Signup')
    // },
    {
      path: '/you',
      name: 'you',
      component: () => import(/* webpackChunkName: "you" */ '@/views/Account')
    },
    {
      path: '/rules',
      name: 'rules',
      component: () => import(/* webpackChunkName: "about" */ '@/components/Rules')
    },
    {
      path: '/how',
      name: 'how',
      component: () => import(/* webpackChunkName: "how" */ '@/views/How')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import(/* webpackChunkName: "pages" */'@/views/Privacy')
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import(/* webpackChunkName: "pages" */ '@/views/Terms')
    },
    {
      path: '/game/:id',
      name: 'game',
      component: () => import(/* webpackChunkName: "game" */ '@/views/Game')
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  }
})
