<template>
  <div class="flex flex-col flex-auto min-h-home">
    <div class="p-2 flex-auto mt-10 pt-2 flex items-center justify-center">
      <div v-if="loggedIn" class="p-3 text-center text-white w-full max-w-md">
        <div class="mb-6">
          <button @click="newGame" class="bg-red-600 hover:bg-blue-600 font-medium btn">
            <span v-if="me.guest">Play the NADA Bot 🤖</span>
            <span v-else>New game</span>
          </button>
        </div>
        <div>
          <router-link to="/join" class="bg-pink-300 hover:bg-yellow-400 font-medium btn inline-block">Join a game</router-link>
        </div>
        <div class="mt-6 text-white">
          <p>playing as <router-link to="/you"><span class="text-green-300 font-bold">{{ displayName }}</span><span class="text-gray-300"> &rarr;</span></router-link></p>
          <div v-if="changing">
            <form @submit.prevent="changeName" class="m-2 flex items-stretch">
              <input v-model="newName" v-focus placeholder="A nice name" class="bg-transparent text-center rounded-lg placeholder-gray-200 font-medium p-3 w-full outline-none border border-white"/>
              <div class="ml-3">
                <button class="rounded-lg border border-white h-full px-8 font-medium outline-none">Update</button>
              </div>
            </form>
            <div class="text-center text-xs -mb-4">
              <a @click="changing = false" class="cursor-pointer">&times; cancel</a>
            </div>
          </div>
          <div v-else-if="cantChange" class="mt-2 mb-10">
            <router-link to="/you" class="border-b border-white text-xs">Add an email to create multiplayer games</router-link>
          </div>
          <div v-else-if="guest" class="mt-2 mb-10">
            <router-link to="/you" class="border-b border-white text-xs">Change display name</router-link>
          </div>
        </div>
      </div>
      <div v-else class="p-3 text-center text-white w-full max-w-md">
        <p class="text-lg font-medium">NADA is an online dice game. <br>Play as a guest or login ✨</p>
        <div class="my-6">
          <button @click="start" class="bg-orange-400 hover:bg-blue-600 font-medium btn">Guest</button>
          <p class="my-5 text-sm">or</p>
          <router-link to="/login" class="bg-red-600 hover:bg-blue-600 font-medium btn inline-block">Login</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'home',
    data () {
      return {
        changing: false,
        newName: null
      }
    },
    computed: {
      username: {
        get () {
          return this.$store.state.username
        },
        set (val) {
          this.$store.commit('SET_NAME', val)
        }
      },
      cantChange () {
        if (!this.me.guest) return false
        return this.me.guest && this.me.tempName
      },
      guest () {
        return this.me.guest
      },

      ...mapGetters(['loggedIn', 'displayName', 'me'])
    },
    methods: {
      start () {
        this.$store.dispatch('guest')
      },
      newGame () {
        this.$store.dispatch('newGame').then((game) => {
          this.$router.push(`/game/${game._id}`)
        })

        // firefox needs a click
        if (!('Notification' in window)) return
        Notification.requestPermission()
      },
      changeName () {
        this.username = this.newName
        this.newName = null
        this.changing = false
      }
    },
    mounted () {
      this.$store.dispatch('leaderboard')
    }
  }
</script>
