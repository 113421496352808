<template>
  <div class="my-4">
    <div v-if="cantChange" class="py-1 px-2 rounded border border-white">
      Playing as <span class="text-green-400 font-medium">{{ me.tempName }}</span>
    </div>
    <form v-else @submit.prevent="change">
      <p v-if="me.guest" class="text-2xs mb-1 ml-1">Your display name (can only be changed once)</p>
      <p v-else class="text-2xs mb-1 ml-1">Edit your username:</p>
      <div class="relative flex text-black">
        <input v-model="form.u" @input="unameInput" :class="invalidName" :disabled="cantChange" placeholder="Username* — 2-16 characters" minlength="2" maxlength="16" class="flex-auto rounded py-1 px-2 border border-white" required>
        <transition name="fade">
          <button v-show="showButton" class="py-1 px-3 ml-3 right-0 top-0 absolute btn-scale">→</button>
        </transition>
      </div>
    </form>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'UsernameForm',
    data () {
      return {
        form: { u: null },
        valid: { available: false }
      }
    },
    computed: {
      invalidName () {
        if (!this.form.u) return false
        if (this.form.u === this.me.username) return 'bg-transparent'
        return this.valid.available ? 'bg-green-300' : 'bg-red-200'
      },
      cantChange () {
        if (!this.me.guest) return false
        return this.me.guest && this.me.tempName
      },
      showButton () {
        return this.form.u !== this.me.username && this.valid.available
      }
    },
    methods: {
      unameInput () {
        const name = this.form.u
        if (name && name.length > 1) {
          this.checkUsername(name).then((valid) => {
            this.valid = valid
          })
        } else {
          this.valid = { available: false }
        }
      },
      change () {
        if (!this.valid.available) return
        if (this.me.guest) {
          this.setTempName(this.form).then(() => {
            this.$emit('next')
          })
        } else {
          this.changeUsername(this.form)
        }
      },

      ...mapActions(['checkUsername', 'changeUsername', 'setTempName', 'user', 'logout'])
    },
    mounted () {
      if (!this.loggedIn) return
      this.form.u = this.me.username
    },
    watch: {
      'me.username' (newVal) {
        this.form.u = newVal
      },
      'me.tempName' (newVal) {
        this.form.u = newVal
      }
    }
  }
</script>

<style>
  .btn-scale {
    transform: scale(1.4);
  }
  /*.btn-shadow { box-shadow: 0 0 0 7px #b2f5ea; }*/
</style>
