import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import mixin from '@/mixin'

import Zero from '@/components/dice/Zero'
import One from '@/components/dice/One'
import Two from '@/components/dice/Two'
import Three from '@/components/dice/Three'
import Four from '@/components/dice/Four'
import Five from '@/components/dice/Five'
import Six from '@/components/dice/Six'
import Undef from '@/components/dice/Undef'

Vue.config.productionTip = false

Vue.mixin(mixin)

Vue.component('zero', Zero)
Vue.component('one', One)
Vue.component('two', Two)
Vue.component('three', Three)
Vue.component('four', Four)
Vue.component('five', Five)
Vue.component('six', Six)
Vue.component('undef', Undef)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
