<template>
  <div>
    <div v-if="showName">
      <p :class="{ 'text-right': myMsg }" class="text-3xs mx-2 text-gray-800">
        {{ msg.player ? (msg.player.temp || msg.player.name) : '' }}
      </p>
    </div>
    <div :class="parent" class="flex break-words">
      <div :class="child" class="rounded-chat py-1 px-2 max-w-3xs whitespace-pre-line text-sm lg:text-xs shadow">{{ msg.msg }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ChatMsg',
    props: {
      msg: Object,
      prev: Object
    },
    computed: {
      showName () {
        if (!this.prev || !this.prev.player) return true
        return this.prev.player?.id !== this.msg.player?.id
      },
      myMsg () {
        return this.msg.player?.id === this.me._id
      },
      parent () {
        return this.myMsg ? 'justify-end' : 'justify-start'
      },
      child () {
        if (this.myMsg) {
          return 'bg-blue-600 text-white'
        } else {
          return 'bg-white'
        }
      }
    },
    mounted () {
      const time = new Date(this.msg.when).getTime()
      const now = new Date().getTime()
      if ((now - time) < 10000) {
        this.$el.scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    }
  }
</script>
